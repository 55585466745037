.carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 20px; */
    position: relative;
  }
  
  .items-container {
    display: flex;
    justify-content: center;
    margin: 0 40px;
    transition: all 3s ease-in-out;
  }
  
  .item {
    background-color: white;
    margin: 0 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    color: #313131 !important;
    padding: .2rem .5rem;
    border-radius: .2rem;
  }
  .item:hover {
    background-color: rgba(222, 222, 222, 0.701);
    opacity: 1;
    color: #313131;
  }
  
  .item.active {
    /* opacity: 1; */
    /* background-color: rgba(222, 222, 222, 0.701); */
  }
  
  .previous-button,
  .next-button {
    border: none;
    padding: 5px 6px;
    margin: 0 1px;
    cursor: pointer;
    background-color: rgba(49, 49, 49, 0.0745098039);
  }
  .previous-button:hover,
  .next-button:hover {
    background-color: rgba(49, 49, 49, 0.0745098039);
  }
  
  .previous-button{
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius:  1rem 0 0 1rem ;
  }
  .next-button{
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 0 1rem 1rem 0;
  }
  .link-nav {
    color: #313131 !important;
}

.carousle-container-width{
  width: 450px;
  @media (max-width: 600px) {
    width: 320px;
  }
  @media (max-width: 460px) {
    width: 210px;
  }
}

.item-hide-on-mobole{
  @media (max-width: 460px) {
    display: none;  }
    
  }
  .item-hide-on-tab{
    @media (max-width: 600px) {
      display: none;  }
    
}