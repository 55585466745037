/* .loader {
    border: 15px solid #f3f3f3; 
    border-top: 15px solid #3498db; 
    border-radius: 50%;
    width: 150px;
    height: 150px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  } */

        
/* .mesh-loader {
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.mesh-loader .circle {
    width: 25px;
    height: 25px;
    position: absolute;
    background: #03A9F4;
    border-radius: 50%;
    margin: -12.5px;
    -webkit-animation: mesh 3s ease-in-out infinite;
    animation: mesh 3s ease-in-out infinite -1.5s;
}

.mesh-loader > div .circle:last-child {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.mesh-loader > div {
    position: absolute;
    top: 50%;
    left: 50%;
}

.mesh-loader > div:last-child {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

@-webkit-keyframes mesh {
  0% {
    -webkit-transform-origin: 50% -100%;
            transform-origin: 50% -100%;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    -webkit-transform-origin: 50% -100%;
            transform-origin: 50% -100%;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  50.00001% {
    -webkit-transform-origin: 50% 200%;
            transform-origin: 50% 200%;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform-origin: 50% 200%;
            transform-origin: 50% 200%;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes mesh {
  0% {
    -webkit-transform-origin: 50% -100%;
            transform-origin: 50% -100%;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    -webkit-transform-origin: 50% -100%;
            transform-origin: 50% -100%;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  50.00001% {
    -webkit-transform-origin: 50% 200%;
            transform-origin: 50% 200%;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform-origin: 50% 200%;
            transform-origin: 50% 200%;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
} */

/*       
.circle-loader {
    position: relative;
    background-color: red;

}

.circle-loader div {
    height: 10px;
    width: 10px;
    background-color: #03A9F4;
    border-radius: 50%;
    position: absolute;
    -webkit-animation: 1.3s opaque ease-in-out infinite both;
    animation: 1.3s opaque ease-in-out infinite both;
}

.circle-loader > div:nth-child(1) {
    top: -25px;
    left: 0;
}

.circle-loader > div:nth-child(2) {
    top: -17px;
    left: 17px;
    -webkit-animation-delay: .15s;
    animation-delay: .15s;
}

.circle-loader > div:nth-child(3) {
    top: 0;
    left: 25px;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.circle-loader > div:nth-child(4) {
    top: 17px;
    left: 17px;
    -webkit-animation-delay: 0.45s;
    animation-delay: 0.45s;
}

.circle-loader > div:nth-child(5) {
    top: 25px;
    left: 0;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

.circle-loader > div:nth-child(6) {
    top: 17px;
    left: -17px;
    -webkit-animation-delay: 0.75s;
    animation-delay: 0.75s;
}

.circle-loader > div:nth-child(7) {
    top: 0;
    left: -25px;
    -webkit-animation-delay: .9s;
    animation-delay: .9s;
}

.circle-loader > div:nth-child(8) {
    top: -17px;
    left: -17px;
    -webkit-animation-delay: 1.05s;
    animation-delay: 1.05s;
}

@-webkit-keyframes opaque {
    0%, 40%, 100% {
      opacity: 0.1;
    }
    40% {
      opacity: 1;
    }
}

@keyframes opaque {
    0%, 40%, 100% {
      opacity: 0.1;
    }
    40% {
      opacity: 1;
    }
} */
/* 
.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    animation: pulse 1s linear infinite;
  }
  .loader:after {
    content: '';
    position: absolute;
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: scaleUp 1s linear infinite;
  }
  
  @keyframes scaleUp {
    0% { transform: translate(-50%, -50%) scale(0) }
    60% , 100% { transform: translate(-50%, -50%)  scale(1)}
  }
  @keyframes pulse {
    0% , 60% , 100%{ transform:  scale(1) }
    80% { transform:  scale(1.2)}
  } */


        
/* .hm-spinner{
    height: 115px;
    width: 115px;
    border: 6px solid transparent;
    border-top-color: #2f3c4e;
    border-bottom-color: #2f3c4e;
    border-radius: 50%;
    position: relative;
    -webkit-animation: spin 3s linear infinite;
            animation: spin 3s linear infinite;
  }
  
  .hm-spinner::before{
    content: "";
    position: absolute;
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
    border: 6px solid transparent;
    border-top-color: #2f3c4ed2;
    border-bottom-color: #2f3c4ed2;
    border-radius: 50%;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  
  @-webkit-keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
  }
  
  @keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
  }
   */


 .loader {
    width: 250px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: helvetica, arial, sans-serif;
    text-transform: uppercase;
    font-weight: 900;
    color: #2f3c4e;
    letter-spacing: 0.2em;
}
.loader::before, .loader::after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: #2f3c4e;
    position: absolute;
    animation: load 0.7s infinite alternate ease-in-out;
}
.loader::before {
    top: 0;
}
.loader::after {
    bottom: 0;
}
@keyframes load {
    0% {
        left: 0;
        height: 30px;
        width: 15px;
   }
    50% {
        height: 8px;
        width: 40px;
   }
    100% {
        left: 235px;
        height: 30px;
        width: 15px;
   }
}
