.error-message{
    position: absolute;
    color: red;
    font-size: 12px;
}

.inputError{
    border: 1px solid red !important;
    box-shadow: none !important;
    outline: none !important;
}

.inputclass:focus{
    outline: none !important;
    box-shadow: none !important;
}
.textareaclass{
    border: 1px solid #eff0f1 !important;
}
.textareaclass:focus{
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #eff0f1 !important;
}