.highlight {
  background-color: rgb(238 238 238);
  border-radius: 3px;
  padding: 10px 0;
}
@media print {
  @page {
    size: auto; /* auto is the default value, you can set specific dimensions */
    margin: 25mm 10mm; /* Adjust margins as needed */

    /* Header */
    @top-center {
      content: ""; /* Empty content to remove the default header */
    }

    /* Footer */
    @bottom-center {
      content: ""; /* Empty content to remove the default footer */
    }
  }
}
