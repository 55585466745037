.nav-item-link {
  color: #313131 !important;
}

.nav-item-link-mobile {
  color: white;
  padding: 10px 10px;
  display: inline-block;
  width: 100%;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: #6699cc;
  }
}

.nav-item {
  padding: 10px 20px;
}

.nav-item-mobile {
  padding: 5px 0;
  border-bottom: 1px solid #c3bfbf57;
}

.nav-item:hover {
  border-radius: 0.2rem;
  background-color: #31313113;
}

.active-navClass {
  border-radius: 0.2rem;
  background-color: #3131311f;
}

.hamburger-class {
  position: fixed;
  z-index: 9999;
  top: 2%;
  left: 1%;
}

.nav-right-user-dropdown {
  position: absolute !important;
  z-index: 99999 !important;
  right: 1rem !important;
}

.mobile-navbar {
  position: absolute;
  background-color: #2f3c4e;
  top: 4rem;
  width: 87%;
}

.menu-class {
  cursor: pointer;
}

.user-list-hover:hover {
  background-color: #3131311f;
}

.user-image-container {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  @media (max-width: 992px) {
    height: 40px;
    width: 40px;
  }
}

.user-profile-image {
  height: 100%;
  width: 100%;
}

.show-dropdown-user {
  display: block;
  position: absolute;
  top: 110% !important;
  right: -7px !important;
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 1px 5px rgba(47, 60, 78, 0.5);
  width: auto;
  font-size: 13px;
}

.userbrand:hover {
  opacity: 0.7;
}

// .nav-linkss {
//   border-left: 0.5px solid rgb(203, 203, 203);
// }

// .nav-linkss:last-child {
//   border-right: 0.5px solid rgb(203, 203, 203);
// }
