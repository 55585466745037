.chart-container {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .chart-container {
    width: 100%;
    height: auto;
  }
}
.bord1 {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-radius: 0px !important;
}
.bord2 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0px !important;
  border-radius: 0px !important;
}
.ct-chart-bar {
  height: 400px; /* Adjust the height as needed */
}

/* Style for x-axis labels */
.ct-labels {
  font-size: 12px;
  font-weight: bold;
}

/* Style for y-axis labels */
.ct-vertical {
  font-size: 12px;
}
